import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
let TableDeptUsers = class TableDeptUsers extends Vue {
    get isShowOverlay() {
        return this.list.length > 3;
    }
};
__decorate([
    Prop()
], TableDeptUsers.prototype, "list", void 0);
TableDeptUsers = __decorate([
    Component({
        name: 'TableDeptUsers',
        components: {
            Avatar: () => import('@/components/organization/org-manage/avatar.vue'),
        },
    })
], TableDeptUsers);
export default TableDeptUsers;
